import $ from 'jquery'

const $doc = $(document)
const $mobileNav = $('.js-mobile-nav')
const $hamburger = $('.js-hamburger')

function showMobileNav(event) {
  event.preventDefault()
  $mobileNav.toggleClass('is-active')
  $hamburger.toggleClass('is-active')
  if ( $mobileNav.hasClass('is-active')) {
    $hamburger.attr('aria-expanded', 'true')
  } else {
    $hamburger.attr('aria-expanded', 'false')
  }
}

export default function initMobileNav(){
  $doc.on('click', '.js-hamburger', showMobileNav )
  $doc.on('keyup', function(event) {
    if ( $mobileNav.hasClass('is-active') && (event.key == "Escape") ) {
      $hamburger.attr('aria-expanded', 'false')
      $mobileNav.toggleClass('is-active')
      $hamburger.toggleClass('is-active')
    }
  })
  $doc.on('focusin', function (event) {
    var $target = $(event.target);
    if (!$target.closest('.js-header-wrapper').length) {
      if ( $mobileNav.hasClass('is-active') ) {
        $hamburger.attr('aria-expanded', 'false')
        $mobileNav.toggleClass('is-active')
        $hamburger.toggleClass('is-active')
      }
    }
  })
  $('.overlay-navigation > ul > li.menu-item-has-children > a').click(function (event) {
    event.preventDefault();
    var el = $(event.currentTarget);
    el.parent().toggleClass('is-active');
    if (el.parent().hasClass('is-active')) {
      el.parent().attr("aria-pressed", "true");
      el.parent().attr("aria-expanded", "true");
    } else {
      el.parent().removeAttr("aria-pressed");
      el.parent().attr("aria-expanded", "false");
    }
  });

  $('.overlay-navigation > ul > .menu-item-has-children .back').click(function (event) {
    event.preventDefault();
    $('.is-active').removeClass('is-active');
  });
}
