import $ from 'jquery'

function getCookie(name) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    if (parts.length === 2) {
        return parts.pop().split(";").shift();
    }
    return null;
}

function setCookie(name, value, days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    var expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + "; " + expires + "; path=/";
}

function checkWebinarCookie(){
    var expectedCookie = 'submitted-' + jQuery("form.mktoForm").attr('id');
    var cookieExists = getCookie(expectedCookie);

    if(cookieExists) {
        jQuery(".webinar__ondemand__form").hide();
    }
}

function redirectToThankYou() {
    // Relative URL for the thank you page
    var thankyouUrl = jQuery(".js-ondemand-ty-url").text();
    const thankYouPageURL = thankyouUrl;
    // Redirect to the thank you page
    window.location.href = thankYouPageURL;
}

export default function initWebinars() {

    if (jQuery('.webinar__ondemand__form-inner form').length) {
        checkWebinarCookie();
        jQuery(".webinar__ondemand__form-inner form").on("submit", function (event) {
            event.preventDefault(); // Prevent the form from submitting
            var cookieName = 'submitted-' + jQuery("form.mktoForm").attr('id');
            // Check if the cookieName is valid (not empty)
            if (cookieName) {
                setCookie(cookieName, "true", 10); // Expires in 10 days
            } else {
                console.log('Invalid cookie name. Cannot set the cookie.');
            }

            redirectToThankYou();
        });
    }

}
