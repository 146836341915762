import $ from 'jquery'
const $doc = $(document)


export default function initEventsFunctions() {
    if ( $('.single-event__top--signup').length ) {
        if ($(window).width() < 904) {
            let form = $('.single-event__form--desktop').html();
            $('.single-event__form--desktop').empty();
            $('.single-event__form--mobile').html(form);
        }
        $(window).on('resize', function () {
            if ($(window).width() < 904) {
                if ($('.single-event__form--mobile').is(':empty') ) {
                    let form = $('.single-event__form--desktop').html();
                    $('.single-event__form--desktop').empty();
                    $('.single-event__form--mobile').html(form);
                }
            } else {
                if ($('.single-event__form--desktop').is(':empty')) {
                    let form = $('.single-event__form--mobile').html();
                    $('.single-event__form--mobile').empty();
                    $('.single-event__form--desktop').html(form);
                }
            }
        });
    }
}
