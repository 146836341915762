import $ from 'jquery'
import 'slick-carousel'
const $doc = $(document)

function filterJobs(){
    let val = $(this).attr('id');
    $('.js-filter-jobs').removeClass('active');
    $(this).addClass('active');
    $('.js-dept-panel').hide();
    $('.js-dept-panel[data-department="' + val + '"]').show();
    $([document.documentElement, document.body]).animate({
        scrollTop: $(".job-listings__list").offset().top - 100
    }, 500);
}

function filterJobsDropdown(){
    let val = $(this).val();
    if (val == 'alldepts') {
        $('.js-job').removeClass('hide');
    } else {
        $('.js-job').addClass('hide');
        $('.js-job[data-dept="' + val + '"]').removeClass('hide');
    }
}

function jobsLocationDropdown(){
    let val = $(this).val();
    if (val == 'alllocations') {
        $('.js-job').show();
    } else {
        $('.js-job').hide();
        $('.js-job[data-location="' + val + '"]').show();
    }
}

export default function initJobsFunctions() {
    $doc.on('click', '.js-filter-jobs', filterJobs)
    $doc.on('change', '.js-jobs-dept', filterJobsDropdown)
    $doc.on('change', '.js-jobs-location', jobsLocationDropdown)
    if ('URLSearchParams' in window) {
        let searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has('department')) {
            let $department = '#'+searchParams.get('department');
            $($department).trigger('click');
        }
    }
}
