import initHeadroom from '/lib/headroom'
import initMobileNav from '/lib/mobile-nav'
import initLLMAnimations from '/lib/llm'
import initAjaxFunctions from '/lib/ajax-functions'
import initJobsFunctions from '/lib/jobs'
import initSlickSliders from '/lib/slickSliders'
import initNewsroomFn from '/lib/newsroom'
import initWebinars from './lib/webinar'
import initVideoPopup from '/lib/videoPopup'
import initForumFns from '/lib/forumFns'
import initEventsFunctions from './lib/events'

initHeadroom()
initMobileNav()
initSlickSliders()
initLLMAnimations()
initAjaxFunctions()
initJobsFunctions()
initNewsroomFn()
initWebinars()
initVideoPopup()
initForumFns()
initEventsFunctions()