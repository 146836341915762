import $ from 'jquery'
const $doc = $(document)

function startLlmAnimations(){
	let screenWidth = jQuery(window).width();
	let $window = jQuery(window);
	let $document = jQuery(document);
	let $navGoNext = jQuery(".js-next");
	let $slidesContainer = jQuery(".llm--desktop");
	let $slides = jQuery(".llm__section");
	let $currentSlide = $slides.first();
	let $formHtml = '';
	let isAnimating = false;
	let pageHeight = $window.innerHeight();
	let keyCodes = {
		UP: 38,
		DOWN: 40
	}
	jQuery('.mktoButton.action.primary').text('Request early access');

	setTimeout(function(){
		if (jQuery('.js-llm-form-top .mktoEmailField').length){
			$formHtml = jQuery('.js-llm-form-top').html();
		}
	}, 2000);

	// trigger animation on initial load
	if (screenWidth < 960) {
		mobileAnimation();
	}
	else {
		if (!isAnimating) initDesktopAnimations();
	}

	var switchToDesktop = setInterval(function () {
		if (screenWidth >= 960) {
			if (!isAnimating) {
				initDesktopAnimations()
				clearInterval(switchToDesktop)
			}
		}
	}, 1000);

	jQuery(window).resize(function () {
		screenWidth = jQuery(window).width()
		if (screenWidth < 960) {
			mobileAnimation()
		}
		else {
			jQuery("body").removeClass("llm--mobile-fixed")
		}
	});


	function initDesktopAnimations() {
		goToFirstSlide($currentSlide);
		$window.on("resize", onResize).resize();
		$window.on("mousewheel DOMMouseScroll", onMouseWheel);
		$document.on("keydown", onKeyDown);
		$navGoNext.on("click", goToNextSlide);
	}

	function onKeyDown(event) {
		let PRESSED_KEY = event.keyCode;
		if (PRESSED_KEY == keyCodes.UP) {
			goToPrevSlide();
			event.preventDefault();
		} else if (PRESSED_KEY == keyCodes.DOWN) {
			goToNextSlide();
			event.preventDefault();
		}
	}

	function onMouseWheel(event) {
		let delta = event.originalEvent.wheelDelta / 50 || -event.originalEvent.detail;
		if (delta < -1) {
			goToNextSlide();
		} else if (delta > 1) {
			goToPrevSlide();
		}
	}

	function goToPrevSlide() {
		if ($currentSlide.index() == 2) {
			jQuery('.js-llm-form-bottom').empty();
			if (jQuery('.js-llm-form-top').html() == '') {
				jQuery('.js-llm-form-top').html($formHtml);
			}
		}
		if ($currentSlide.prev().length) {
			goToSlide($currentSlide.prev());
		}
	}

	function goToNextSlide() {
		if ($currentSlide.index() == 2) {
			jQuery('.js-llm-form-top').empty();
			if (jQuery('.js-llm-form-bottom').html() == '') {
				jQuery('.js-llm-form-bottom').html($formHtml);
			}
		}
		if ($currentSlide.next().length) {
			goToSlide($currentSlide.next());
		}
	}

	function goToSlide($slide) {
		if (!isAnimating && $slide.length) {
			isAnimating = true;
			$currentSlide = $slide;
			$slides.removeClass('slide-active');
			setTimeout(function () {
				$currentSlide.addClass('slide-active');
			}, 500);

			TweenLite.to($slidesContainer, 0, { scrollTo: { y: pageHeight * $currentSlide.index() }, onComplete: onSlideChangeEnd, onCompleteScope: this, delay: 0.5 });
		}
	}

	function goToFirstSlide($slide) {
		if (!isAnimating && $slide.length) {
			isAnimating = true;
			$currentSlide = $slide;
			$currentSlide.addClass('slide-active');

			TweenLite.to($slidesContainer, 0, { scrollTo: { y: pageHeight * $currentSlide.index() }, onComplete: onSlideChangeEnd, onCompleteScope: this, delay: 0.5 });
		}
	}

	function onSlideChangeEnd() {
		setTimeout(function () {
			isAnimating = false;
		}, 500);
	}

	function onResize(event) {
		let newPageHeight = $window.innerHeight();

		if (pageHeight !== newPageHeight) {
			pageHeight = newPageHeight;
			TweenLite.set([$slidesContainer, $slides], { height: pageHeight + "px" });
			TweenLite.set($slidesContainer, { scrollTo: { y: pageHeight * $currentSlide.index() } });
		}
	}

	// Mobile tweaks
	function mobileAnimation() {
		jQuery("body").addClass("llm--mobile-fixed")
		jQuery('html').bind('mousewheel DOMMouseScroll touchstart', function (e) {
			jQuery(".llm--mobile__hero").addClass("llm--mobile__hero--small")
			setInterval(function () {
				jQuery("body").removeClass("llm--mobile-fixed")
			}, 1000)
		});
	}

	var waitForm = setInterval(function () {
		if (jQuery(".mktoButton.action.primary").text()) {
			var submitText = jQuery(".mktoButton.action.primary").text();
			jQuery(".js-llm-submit-mobile").attr('value', submitText);
			clearInterval(waitForm);
		}
	}, 100);

	jQuery(".llm--mobile__form-clone form").submit(function (e) {
		e.preventDefault();
		var mail = jQuery(this).parent().find(".llm-static-mail").text();
		jQuery("input.mktoField.mktoEmailField").text(mail);
		jQuery("input.mktoField.mktoEmailField").attr('value', mail);

		var waitEmailUpdate = setInterval(function () {
			if (jQuery("input.mktoField.mktoEmailField").text()) {
				jQuery(".mktoButton.action").click();
				clearInterval(waitEmailUpdate);
			}
		}, 100)

		var confirmation = jQuery(".llm--mobile__confirmation");
		jQuery(".llm--mobile__form-box").hide();
		jQuery(".llm--mobile__list").hide();
		jQuery(".llm--mobile__static-form").hide();
		jQuery(confirmation).show();
	});

	jQuery(".llm--mobile__list-item-title").on('click', function () {
		var video = jQuery(this).parent().find('.llm--mobile__list-video');
		var plus = jQuery(this).find('.llm--mobile__plus');
		var minus = jQuery(this).find('.llm--mobile__minus');
		jQuery(video).slideToggle();
		jQuery(plus).toggle();
		jQuery(minus).toggle();
	});
}

export default function initLLMAnimations() {
	if (jQuery('body').hasClass('page-template-page-llm')) {
		startLlmAnimations();
	}
}
